@import "variables";

.title {
  padding: 0;
  margin: $margin-base 0;
  font-size: 16px;
  color: $text-color;
  text-align: center;
  font-weight: 500;
  height: 42px;
  overflow: hidden;
}

.add {
  width: 100%;
  margin-top: $margin-base;
}
