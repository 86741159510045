@import "variables";

.container {
  article {
    margin: 0 !important;
    width: 225px !important;

    @include mobile {
      & + article {
        margin-top: 60px !important;
      }
    }
  }
}
