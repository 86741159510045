@import "variables";

.outerWrapper {
  position: relative;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
}

.center {
  flex-grow: 10;
  text-align: center;
  border-top: 2px solid $action-color;
  border-bottom: 2px solid $action-color;
  color: $action-color;
  font-weight: bold;
  padding: $padding-base - 2px 0;
  line-height: 1.25;
}

.error {
  position: absolute;
  font-size: 14px;
  line-height: 1.3;
  color: $error-color;
  display: block;
  text-align: center;
  width: 100%;
}
