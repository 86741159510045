@import "variables";

.wrapper {
  text-align: center;
  font-size: 16px;
}

.original {
  text-decoration: line-through;
}

.new {
  color: $action-color;
}
